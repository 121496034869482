import { NumericInput, Select } from '@components/Reusables';
import { FINTOC_ICON } from 'images/GCP';
import { MASTERCARD_ICON } from 'images/GoogleCloud';
import React, { useState } from 'react';
import useStyles from './Automatic.styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface props {
  items: {
    id: number;
    text: string;
  }[];
}

const Automatic = ({ items }: props) => {
  const classes = useStyles();

  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className={classes.card}>
          <div className="grid">
            <span className="bold">Agrega tu pago automático (PAC)</span>
            <span>
              <span>Powered by</span>
              <img src={FINTOC_ICON} />
            </span>
          </div>
          <div className={classes.description}>
            Esto te permitirá conectar una cuenta bancaria para hacer depósitos
            directamente desde tu cuenta corriente.{' '}
            <strong>Sólo tendrás que hacerlo 1 vez.</strong>
          </div>
          <ul className={classes.containerItem}>
            {items.map((item) => {
              return (
                <li className={classes.item} key={item.id}>
                  {item.text}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Automatic;
