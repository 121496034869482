import React from 'react';
import { makeStyles } from '@material-ui/core';
import { parseNumber } from '@utils';

interface props {
  title: string;
  subtitle: string;
  amount: number;
  icon: string;
}

const SuccessfulCard = ({ title, subtitle, amount, icon }: props) => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.container}>
        <img src={icon} />
        <div>
          <span className="title">{title}</span>{' '}
          <span className="subtitle">{subtitle}</span>
        </div>
        <div className={classes.alignEnd}>
          <span className="title">${parseNumber(amount, 2)}</span>
          <span className="subtitle">Cada transacción</span>
        </div>
      </div>
    </section>
  );
};

export default SuccessfulCard;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    color: 'white',
    minWidth: '21rem',
    width: '90%',
    margin: '0 auto',
    borderRadius: 8,
    border: '1px solid rgba(255, 255, 255, 0.5)',
    padding: '0.5rem 1rem',
    '& >div': {
      gap: '0.2rem',
      display: 'flex',
      flexDirection: 'column',
      '& .subtitle': {
        fontWeight: 300,
        fontFamily: 'Nunito',
        fontSize: '0.625rem',
      },
      '& .title': {
        fontWeight: 600,
        fontFamily: 'Nunito',
        fontSize: '0.875rem',
      },
    },
  },
  alignEnd: {
    textAlign: 'end',
  },
}));
