import React, { useEffect, useState } from 'react';
import { LayoutMobileNewVersion } from '@components/Structural/Goal/NewVersion/LayoutMobile';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import TypeRules from '@components/SmartSave/Create/TypeRules/TypeRules';
import DescriptionRule from '@components/SmartSave/Create/DescriptionRule/DescriptionRule';
import AmountAndFrequency from '@components/SmartSave/Create/AmountAndFrequency/AmountAndFrequency';
import { ButtonPrimary, ButtonText, Select } from '@components/Reusables';
import AdviceAndAmount from '@components/SmartSave/Create/AdviceAndAmount/AdviceAndAmount';
import AmountOfMovements from '@components/SmartSave/Create/AmountOfMovements/AmountOfMovements';
import SwitchPercentageAmount from '@components/SmartSave/Create/SwitchPercentageAmount/SwitchPercentageAmount';
import Automatic from '@components/SmartSave/Create/PAC/Automatic';
import PAC from '@components/SmartSave/Create/PAC/PAC';
import Successful from '@components/SmartSave/Successful/Successful';
import SuccessfulCard from '@components/SmartSave/Successful/SuccessfulCard/SuccessfulCard';
import { MONEY_BACKGROUND_DARK_GREEN_ICON } from 'images/GCP';
import SuccessfulItem from '@components/SmartSave/Successful/SuccessfulItem/SuccessfulItem';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { useGoals } from '@apollo';
import CardWarning from '@components/SmartSave/CardWarning/CardWarning';

const CreateNewRule = () => {
  const classes = useStyles();
  const [isSelected, setSelected] = useState(0);
  const [newRuleStep, setNewRuleStep] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1300px)');
  const [goalValue, setGoalValue] = useState<number>(0);
  const [targetOptions, setTargetOptions] = useState([
    { value: 'null', name: 'No hay objetivo' },
  ]);
  const { goals } = useGoals();

  function getTargetOptions(targetData) {
    const targetArray = [];

    targetData.forEach((element) => {
      targetArray.push({ value: element.id, name: element.name });
    });

    setGoalValue(0);

    return targetArray;
  }

  useEffect(() => {
    setTargetOptions(getTargetOptions(goals));
  }, [goals]);

  const [isCardWarning, setIsCardWarning] = useState(false);

  useEffect(() => {
    isSelected === 1 ? setIsCardWarning(true) : setIsCardWarning(false);
  }, [setSelected, isSelected]);

  return newRuleStep === 0 ? (
    <LayoutMobileNewVersion
      className={classes.height}
      stepNumber={'0'}
      pageName={'Crear nueva Regla'}
      onBackClick={() => navigate(Route.finance + '?#smartSave')}
    >
      <div>
        {isCardWarning && !isMobile && (
          <CardWarning
            className={classes.classNameCardWarning}
            title="OJO"
            subtitle="Ya tienes una regla de redondeo, por lo que se duplicará el aporte cuando compres."
            close={() => setIsCardWarning(false)}
          />
        )}
      </div>

      <div className={classes.grid}>
        <TypeRules setSelected={setSelected} isSelected={isSelected} />
        {isCardWarning && isMobile && (
          <CardWarning
            className={classes.classNameCardWarning}
            title="OJO"
            subtitle="Ya tienes una regla de redondeo, por lo que se duplicará el aporte cuando compres."
            close={() => setIsCardWarning(false)}
          />
        )}
        <DescriptionRule
          text={
            isSelected === 0 ? (
              <>
                Deposita automáticamente en tu objetivo un monto fijo cada
                cierto tiempo.{' '}
                <strong>Por ejemplo: ahorrar $1.000 todos los días.</strong>
              </>
            ) : isSelected === 1 ? (
              <>
                Redondea tu compra al siguiente mil y apórtalo a tu objetivo.
                <strong>
                  {' '}
                  Por ejemplo si compras un artículo por $2.650 se ahorrarán
                  $350 (redondeo a $3.000).
                </strong>
              </>
            ) : (
              <>
                Aporta a tu objetivo cada vez que hagas un movimiento con tu
                cuenta o tarjeta.{' '}
                <strong>
                  Por ejemplo: por cada movimiento menor a $50.000, aportarás
                  $1.000 a tu objetivo.
                </strong>
              </>
            )
          }
        />
        <section>
          <div className={classes.selectorDestine}>
            <div className="title">Destino</div>
            <div className={classes.selector}>
              <Select
                name="sort"
                value={goalValue}
                onChange={(e) => setGoalValue(e.target.value)}
                size={'large'}
                options={targetOptions}
              />
            </div>
            {/* deben ser los obj */}
            {!isMobile && isSelected === 2 && <AmountOfMovements />}
          </div>
        </section>{' '}
        {isSelected === 1 ? (
          <AdviceAndAmount />
        ) : (
          isSelected === 0 && <AmountAndFrequency />
        )}
        {isMobile && isSelected === 2 && <AmountOfMovements />}
        {isSelected === 2 && <SwitchPercentageAmount />}
      </div>
      <div className={classes.containerButtons}>
        <ButtonPrimary
          text="Continuar"
          onClick={() => {
            setNewRuleStep(1);
          }}
        />
        <ButtonText text="Cancelar" onClick={() => {}} />
      </div>
    </LayoutMobileNewVersion>
  ) : newRuleStep === 1 ? (
    <LayoutMobileNewVersion
      className={classes.height}
      stepNumber={'1'}
      pageName={'NUEVA REGLA'}
      onBackClick={() => setNewRuleStep(0)}
    >
      {isSelected !== 0 ? (
        <PAC insideCard items={specifications} />
      ) : (
        <Automatic items={specifications} />
      )}
      <div className={classes.containerButtons}>
        <ButtonPrimary
          text={isSelected === 0 ? 'Agregar PAC' : 'Crear regla'}
          onClick={() => {
            setNewRuleStep(2);
          }}
        />
        <ButtonText text="Cancelar" onClick={() => setNewRuleStep(0)} />
      </div>
    </LayoutMobileNewVersion>
  ) : (
    <Successful title="¡Tu regla de ahorro se ha creado!">
      <SuccessfulCard
        title="Viaje Amsterdam"
        subtitle="Redondeo"
        amount={999}
        icon={MONEY_BACKGROUND_DARK_GREEN_ICON}
      />
      {items.map((item) => (
        <SuccessfulItem
          key={item.id}
          title={item.title}
          subtitle={item.subtitle}
        />
      ))}
      <ButtonPrimary
        className={classes.button}
        text="¡Listo!"
        onClick={() => {}}
      />
    </Successful>
  );
};
export default CreateNewRule;

const useStyles = makeStyles((theme) => ({
  height: {
    '& >section': {
      height: '8rem',
      '& >div >div': {
        [theme.breakpoints.up(1300)]: {
          width: '70%!important',
        },
      },
    },
    '& .children': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      minHeight: '80vh',
      marginTop: '1.5rem',
      [theme.breakpoints.up(1300)]: {},
    },
  },
  grid: {
    display: 'grid',
    gap: '1rem',
    [theme.breakpoints.up(1300)]: {
      display: 'grid',
      gridTemplateColumns: '430px 430px',
      justifyContent: 'center',
      gap: '2rem',
    },
  },
  background: {
    background: '#eaeaea',
    minHeight: '100vh',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  selectorDestine: {
    background: 'white',
    borderRadius: 8,
    width: '90%',
    margin: '0 auto',
    padding: '1rem 1.8rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [theme.breakpoints.up(1300)]: {
      height: '7rem',
      width: '440px',
    },
    '& .title': {
      color: '#646464',
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  selector: {
    '& >div': {
      width: '100%',
    },
  },
  button: {
    background: '#e6f3f5',
    color: '#282828',
    width: '17.8rem',
    margin: '4rem auto 0',
    display: 'flex',
    '& span': {
      color: '#282828',
    },
  },
  containerButtons: {
    '& button': {
      width: '285px',
      textDecoration: 'none',
      [theme.breakpoints.up(1300)]: {
        width: '200px',
      },
    },
    width: '285px',
    margin: '2rem auto 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    [theme.breakpoints.up(1300)]: {
      flexDirection: 'row-reverse',
      paddingRight: '2rem',
      width: '983px',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'end',
      marginTop: '3rem',
      gap: '0rem',
    },
  },
  classNameCardWarning: {
    width: '910px',
    justifyContent: 'end',
    display: 'flex',
    margin: '0 auto',
    [theme.breakpoints.down(1300)]: {
      justifyContent: 'center',
      width: '100%',
    },
    '& >div': {
      position: 'absolute',
      top: '2rem',
      [theme.breakpoints.down(1300)]: {
        position: 'relative',
        top: '0rem',
        width: '90%',
      },
    },
  },
}));

const items = [
  {
    id: 0,
    title: 'Viaje Amsterdam',
    subtitle: 'Destino',
  },
  {
    id: 1,
    title: 'Depósito automático',
    subtitle: 'Se hará un aporte automático a tu objetivo',
  },
  {
    id: 2,
    title: 'Aporte desde cuenta corriente',
    subtitle: '**** **** **** 1234',
  },
];

const specifications = [
  {
    id: 0,
    text: 'Nunca te cargaremos si no tienes saldo suficiente',
  },
  {
    id: 1,
    text: 'Rokin cobrará un 1% de comisión por transferencia. (Cada $ 1.000 CLP cobraremos $10 CLP)',
  },
];
