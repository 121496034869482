import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .title': {
      color: '#646464',
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      '& span': {
        textTransform: 'lowercase',
        fontWeight: 500,
      },
    },
  },
  description: {
    fontSize: '0.75rem',
    color: '#959595',
    fontWeight: 300,
    '& strong': {
      fontWeight: 'bold',
    },
  },
  radioGroup: {
    '& span': {
      fontSize: '0.85rem',
    },
  },
  container: {
    width: '90%',
    margin: '0 auto',
    color: '#646464',
    background: 'white',
    borderRadius: 8,
    padding: '1rem 1.8rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up(1300)]: {
      maxWidth: 600,
      padding: '1rem 4rem',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #979799',
    borderRadius: 17,
    padding: '0.2rem 0.2rem',
    '& div:hover': {
      cursor: 'pointer',
    },
  },
  optionSwitch: {
    fontSize: '0.75rem',
    padding: '0.2rem 0.8rem',
    color: '#979799',
  },
  selected: {
    background: '#008195',
    borderRadius: 17,
    color: 'white',
  },
  itemWidth: {
    width: '11.25rem',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  card: {
    border: '1px solid #00a9c7',
    borderRadius: 8,
    padding: '1rem 0.8rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    color: '#959595',
    fontSize: '0.75rem',
    '& .bold': {
      fontWeight: 'bold',
      color: '#1a1a1a',
      fontSize: '0.875rem',
    },
    '& .grid': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '1rem',
      '& span': {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
          width: '7rem',
        },
      },
    },
  },
  change: {
    color: '#00a9c7',
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& svg': {
      width: '1rem',
      color: '#00a9c7',
    },
  },
  containerItem: {
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  item: {
    listStyle: 'inside',
    color: '#646464',
    fontSize: '0.75rem',
  },
}));

export default useStyles;
