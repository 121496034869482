import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {},
  container: {
    color: 'white',
    border: '1px solid white',
    padding: '0.5rem',
    borderRadius: 15,
    width: '357px',
    height: '85px',
    [theme.breakpoints.down(1300)]: {
      color: '#0A5A64',
      border: '1px solid #0A5A64',
    },
  },
  flex: {
    display: 'flex',
    gap: '1rem',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: '0.75rem',
    width: '19rem',
    margin: '0 0 0 auto',
    [theme.breakpoints.down(1300)]: {
      width: '90%',
    },
  },
  title: {
    fontWeight: 'bolder',
    fontSize: '0.875rem',
  },
}));

export default useStyles;
