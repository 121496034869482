import React, { useContext, useCallback, useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { MovementType, PortfolioLocationCategory } from '@interfaces';
import { ProfileContext, NewInvestContext, AppContext } from '@context';
import { useGoals, useCurrentUser } from '@apollo';

interface CreateGoalProps {
  children: React.ReactNode;
  title: string;
}

const Successful: React.FC<CreateGoalProps> = ({ children, title }) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const classes = useStyles();

  return (
    <>
      <section className={classes.containerData}>
        {!isMobile && <div className={classes.title}>🎉 {title}</div>}
        <div>
          <div className={classes.top}>
            {isMobile && <div className={classes.title}>🎉 {title}</div>}
            <div className={classes.imgMobile}>
              <img
                src={
                  'https://storage.googleapis.com/rokinapp-images/SmartSave/135368-3d-bank-vault%201.svg'
                }
              />
            </div>
          </div>
          <div className={classes.cardGreen}>
            <div className={classes.blockContainer}>{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Successful;

const useStyles = makeStyles((theme) => ({
  containerData: {
    background: '#191b34',
    fontFamily: 'Montserrat',
    [theme.breakpoints.up(1300)]: { display: 'block' },
    '& >div': {
      width: '100%',
      [theme.breakpoints.up(1300)]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
      },
    },
    paddingLeft: 0,
    paddingTop: 0,
    justifyContent: 'center',
    [theme.breakpoints.up(1300)]: {
      minHeight: '100vh',
    },
  },
  blockContainer: {
    height: '10rem',
  },
  title: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '0.875rem',
    margin: '0rem auto 2rem',
    paddingTop: '3rem',
    textAlign: 'center',
    [theme.breakpoints.up(1300)]: {
      padding: '4rem 0 5rem 0',
    },
  },
  cardGreen: {
    background: '#0d0e1c',
    borderRadius: '20px 20px 0 0',
    minHeight: '64vh',
    paddingTop: '2rem',
    [theme.breakpoints.up(1300)]: {
      maxWidth: 600,
      padding: '1rem 4rem',
      height: '15rem',
      paddingTop: '3rem',
      minHeight: '28rem',
      borderRadius: '20px',
    },
  },
  subtitle: {
    width: 350,
    padding: '30px 0 30px',
    fontSize: '0.875rem',
    textAlign: 'center',
    color: 'white',
    margin: '0 auto',
  },
  dataRight: {
    height: '7rem',
    background: 'white',
    boxShadow: '1px 5px 10px rgba(0,0,0,0.2)',
    borderRadius: 16,
    marginBottom: '3rem',
    width: 350,
    margin: '1rem auto 3rem',
    display: 'flex',
    alignItems: 'center',

    '& img': {
      width: 67,
      margin: 18,
    },
  },
  right: {
    paddingLeft: '0.5rem',
    '& span': {
      display: 'block',
    },
  },
  goal: {
    paddingTop: '0.5rem',
    display: 'flex',
    gap: '2rem',
    '& .title': {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: 'Montserrat',
      display: 'flex',
      gap: '0.2rem',
      alignItems: 'center',
      '& svg': {
        width: 20,
        color: theme.palette.primary.main,
      },
    },
    '& .subtitle': {
      fontSize: 12,
      fontWeight: 500,
      color: '#6B6B6B',
      paddingTop: '0.2rem',
    },
  },
  button: {
    justifyContent: 'space-between',
    display: 'block',
    width: 350,
    margin: '0 auto',
    '& > button': {
      margin: '0 auto 14px',
      width: '100%',
      fontSize: 16,
      height: 50,
      color: '#54e5ff',
    },
  },
  imgMobile: {
    '& img': {
      margin: '0 auto 2rem',
      width: '250px',
      [theme.breakpoints.up(1300)]: {
        width: '400px',
      },
    },
  },
  top: {
    minHeight: '30vh',
  },
}));
