import React from 'react';
import { makeStyles } from '@material-ui/core';

interface props {
  title: string;
  subtitle: string;
}

const SuccessfulItem = ({ title, subtitle }: props) => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.container}>
        <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/check_icon%20(6).svg" />
        <div>
          <span className="title">{title}</span>{' '}
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
    </section>
  );
};

export default SuccessfulItem;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem 0 0',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    color: 'white',
    minWidth: '21rem',
    width: '90%',
    margin: '0 auto',
    '& >div': {
      gap: '0.2rem',
      display: 'flex',
      flexDirection: 'column',
      '& .subtitle': {
        fontWeight: 300,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
      '& .title': {
        fontWeight: 600,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
    },
  },
}));
