import React from 'react';
import useStyles from './CardWarning.styles';
import { WARNING_GREEN_ICON, WARNING_WHITE_ICON } from 'images/GCP';
import Close from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';

interface props {
  title: string;
  subtitle: string;
  className?: string;
  close?: () => void;
}

const CardWarning = ({ title, subtitle, className, close }: props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1300px)');

  return (
    <div className={`${classes.containerAll} ${className}`}>
      <div className={classes.container}>
        <div className={`${classes.flex} ${classes.justifySpaceBetween}`}>
          <div className={classes.flex}>
            <img src={!isMobile ? WARNING_WHITE_ICON : WARNING_GREEN_ICON} />
            <span className={classes.title}>{title}</span>
          </div>
          <Close onClick={close} />
        </div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

export default CardWarning;
